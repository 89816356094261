
@media only screen and (max-width: 639px) {
    nav, ul, li {
        display: none;
    }

    .pc-view{
        display: none;
    }

    .BellowButton {
        display: none;
    }
    
    .MobileArrowNext2btn {
        display: block;
    }

    .DesktopContactUsFrom {
        display: none;
    }

    .MobileContentUsForm {
        display: block;
    }


body {
    overflow:scroll;
    overflow-x:hidden;
}

button{
    border: none;
    background: none;
    cursor: pointer
}

.menu-logo {
    width: 6.656vw;
    height: 6.4vw;
    margin-left: 3vw;
    margin-top: 1.5vw;

}

.mobile-logo {
    width: 33vw;
    height: 15vw;
    margin-left: 24vw;
    margin-top: 1.5vw;
}

.mobile-view {
    text-align: center;
    width: 100%;
    height: 20vw;
    display: flex;
    align-content: center;
    justify-content: left;
    align-items: center;
    padding: 14px 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10);
}

.ElementSelected {
    color: #57007B;
    font-weight:bold;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #57007B;
    text-underline-offset: 6px;
}

.sidenav {
    display: none;
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 20px;
}

.CloseButton {
    margin-top: -2vw;
    margin-left: 30vw;
}

.CloseIcon {
    width: 8vw;
    height: 8vw;
}

.sidenav button {
  padding: 15px 6px 6px 32px;
  text-decoration: none;
  font-size: 4.267vw;
  color: #4A5568;
  display: block;
}


.dropbtn {
    list-style: none;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 510;
    line-height: 25px;
    color: #4A5568;
    background: none;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: center;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    transform: scale(1);
    color: #171717;
    font-weight:bold;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #F76680;
    text-underline-offset: 6px;
}



.logo {
    width: 5vw;
    cursor: pointer;
    /*margin-right: 5rem;*/
}

.btn {
    width: 124px;
    height: 42px;
    padding-left: 25px;
    padding-right: 25px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    display: inline-flex;

    border: none;
	cursor: pointer;
	outline: inherit;
}

.btn-div {
    padding-top: 20px;
    /*margin-left: 5vw;*/
}

.Home {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2vh ;
    /*background-color: black;*/
}


.Content {
    display: none;
}

.MobileContent {
    display: block;
    height: 80vw;
}

.span-1 {
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 300;
    line-height: 6.4vw;
    word-wrap: break-word
}

.Across {
    display: flex;
    justify-content: center;
    margin-top: 1vw;
    height: 2vw;
}

.webDevMobile {
    width: 51.608vw;
    height: 38.706vw;
    margin-top: 6vw;
}

.span-2 {
    color: #1A202C;
    font-size: 5.333vw;
    font-weight: 800;
    line-height: 6.4vw;
    word-wrap: break-word
}

.span-3 {
    font-size: 5.333vw;
    font-weight: 800;
    line-height: 6.4vw;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.span-4 {
    color: #4A5568;
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 5.185vw;
    word-wrap: break-word
}

.span-5 {
    color: #4A5568;
    font-size: 3.2vw;
    font-weight: 700;
    line-height: 2.817vw;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.LetsConnect {
    width: 190px;
    height: 52px;
    padding: 20px 30px;
    background: #57007B;
    box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    margin-top: 3rem;

    border: none;
	cursor: pointer;
	outline: inherit;
}

.LetsConnectBtn {
    color: #FAFAFA;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.875rem;
    word-wrap: break-word;
}

.RoundIconTop {
    justify-content: center;
    display: flex;
    margin-right: 30rem;
}

.RoundIconBottom {
    display: none;
    justify-content: center;
    display: flex;
    margin-right: 75rem;
    margin-top: -1.8rem;
}

.BellowButton {
    width: 4rem;
    height: 4rem;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%); border-radius: 9999px;
}

.RoundGradiant {
    display: none;
}


.Core {
    width: 100%;
    height: 78vw;
    background-color: #F9F9FF;
    position: relative;
    border-bottom: 2px solid #E7DAED;
    margin-top: 5vw;
}

.SliderDiv {
    justify-content: left;
    display: flex;
    margin-top: 0;
    margin-right: 0;
    padding-left: 3.5vw;
    gap: 45vw;
}

.SliderDivContainer {
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word;
    margin-top: 3vw;
}

.SlidersClass {
    width: 87vw;
    height: 55.467vw;
    background: #FAFAFA;
    border-radius: 1.867vw;
    cursor: pointer;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
    margin-top: 1vw;
    margin-left: 1vw;

}

.SwiperSlideOverride{
    width: 300px;
}

.SlidersClass:hover {
    margin-top: 2vw;
    border: 1px #F76680 solid;
    transition: 0.8s;
}

.SlidersClassDiv {
    width: 51.332vw;
    height: 32.551vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    margin-top: 7.2vw;
    margin-left: 4vw;
}

.SlidersClassDiv2 {
    width: 51.332vw;
    height: 32.551vw;
    position: relative;
}

.SlidersClassDiv3 {
    width: 10.667vw;
    height: 10.667vw;
    left: 0;
    top: 0;
    position: absolute;
    background: #FAFAFA;
    border-radius: 9999px;
    border: 1px #F76680 solid
}

.SlidersClassImg {
    width: 6.4vw;
    height: 6.4vw;
    left: 2vw;
    top: 2vw;
    position: absolute;
}

.swiper1 {
    width: 100%;
    height: 65vw;
    margin-top: 3vw;
    margin-left: 2.5vw;
    /*display: flex;*/
    /*justify-content: left;*/
    /*gap: 1.5rem;*/
}

.SliderContent {
    width: 80.267vw;
    /*height: 25px;*/
    /*color: #F76680;*/
    font-size: 3.733vw;
    font-weight: 600;
    line-height: 5.6vw;
    word-wrap: break-word;
    /*padding-top: 15px;*/
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 12.52vw;
    padding-bottom: 1.408vw;
}

.SliderText {
    width: 80.267vw;
    color: #6F6F6F;
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 5.185vw;
    word-wrap: break-word;
    /*margin-top: 0.7rem;*/
}

.WhyChooseUsDiv{
    margin-top: 75vw;
}

.WhyChooseDiv {
    display: block;
    margin-right: 57.5rem;
    margin-left: 4vw;
    width: 100%;
    margin-top: 14vw;
}

.WhyChoose {
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word;
}

.ITExperts {
    display: block;
    justify-content: center;
    margin-top: 3vh;
    gap: 0;
}

.ITExperts image {
    width: 601rem;
    height: 460rem;
    border-radius: 20px;
}

.ITExpertsSpan1 {
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 400;
    line-height: 6.4vw;
    word-wrap: break-word;
    margin-top: 2rem;
}

.ITExpertsSpan2 {
    font-weight: 700;
}

.ITExpertsSpan3 {
    color: #718096;
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 4.8vw;
    word-wrap: break-word
}

.ITExpertsSpan4 {
    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ItExportsImgDiv {
    display: none;
}

.MobileItExportsImgDiv {
    position: relative;
    display: inline-block;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 20px;
    transition: 0.08s;
    margin-top: 3vw;
}

.ItExportsImg {
    width: 92vw;
    height: 52vw;
}

.MobileItExportsImgDiv:hover img {
    transform: none;
    transform-origin: 50% 50%;
    transition: 0.08s;
}



.ITExpertsDiv1 {
    margin-top: 0;
}

.ITExpertsDiv2 {
    width: 93.333vw;
    height: 100%;
    margin-top: 1vw;
}

.ITExpertsMargin {
    margin-left: 4vw;
    /*height: 90vw;*/
}

.Gradiant {
    width: 6.933vw;
    height: 1.333vw;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    margin-bottom: 4px;
}

.Vector{
    display: block;
    justify-content: center;
}

.Vector1 {
    display: none;
}

.Vector2 {
    display: none;
}

@keyframes rotateImage {
  0%, 100% {
    transform: rotateZ(10deg); /* No rotation */
  }
  50% {
    transform: rotateZ(-1deg); /* Rotate to the left */
  }

}

.Vectordiv1 {
    /*width: 44.25px;*/
    /*height: 44.25px;*/
    position: absolute;
    margin-left: -2rem;
    margin-top: -1.75rem;
}

.Vectordiv2 {
    margin-bottom: -1rem;
    margin-left: -0.5rem;
}

.Vectordiv3 {
    width: 14.34px;
    height: 23.47px;
    left: 15.16px;
    top: 10.39px;
    position: absolute;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%)
}

/*.VectorSpan {*/
/*    display: flex;*/
/*}*/

.VectorSpan1 {
    color: #718096;
    font-size: 3.733vw;
    font-weight: 600;
    line-height: 5.6vw;
    word-wrap: break-word;
}

.VectorSpan2 {
    font-size: 3.733vw;
    font-weight: 600;
    line-height: 5.6vw;
    word-wrap: break-word;

    -webkit-font-smoothing: antialiased;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.VectorSpanDiv {
    width: 100%;
    display: block;
    justify-content: center;
    margin-top: 13vw;
    margin-left: 4vw;
}

.VectorSpanDiv1 {
    color: #718096;
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 4.8vw;
    word-wrap: break-word;
    margin-left: 4vw;
}

.OurExports {
    height: 142vw;
    background: #F7F7FA;
    margin-top: 10vw;
}

.EllipseClass {
    display: none;
}

.Ellipse {
    width: 18px;
    height: 18px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 9999px;
}

.OurExportsDiv {
    display: block;
    justify-content: center;
    padding-top: 6vw;
    gap: 2rem;
    overflow: hidden;
}

.OurExportsDivMobile {
    padding-top: 0;
}

.OurExportsDiv3{
    display: flex;
    justify-content: left;
    padding-top: 0;
    gap: 60vw;
}

.OurExportsDiv3button{
    display: block;
}

.MobileArrowNext {
    display: inline;
}

.MobileArrowNext2 {
    display: inline;
    margin-top: 4.1vw;
}

.OurExportsDiv2 {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    gap: 2rem;
    overflow: hidden;
    margin-top: 3vw;
}

.LineGradientSubDiv1 {
    width: 6.933vw;
    height: 1.333vw;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    margin-left: 4vw;
}

.OurExportsText {
    text-align: left;
    margin-left: 4vw;
}

.OurExportsTextMobile {
    margin-bottom: 5vw;
}

.OurExportsSpan1 {
    display: none;
}

.OurExportsSpan2 {
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word
}
.OurExportsSpan2Mobile3 {
    display: inline;
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word
}

.OurExportsSpan2Mobile{
    display: inline;
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word
}

.MobileOurExportsSpan1 {
    display: inline;
    color: #1A202C;
    font-size: 4.267vw;
    font-weight: 700;
    line-height: 6.4vw;
    word-wrap: break-word
}

.ExportsImgDivMargin {
    display: block;
    justify-content: center;
    margin-left: 4vw;
}

.ExportsImgDiv {
    position: relative;
    display: inline-block;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 13px;
    width: 86.133vw;
    height: 101.067vw;
    transition: 0.08s;

}

.ExportsImg {
    width: 86.133vw;
    height: 101.067vw;
    border-radius: 3.467vw;
    cursor: pointer;
    overflow: hidden;
    display: block;
    transition: 0.08s;
}

.ExportsImgDiv:hover .ExportsImg {
    transform: none;
    transform-origin: 50% 50%;
    transition: 0.08s;
}

.ExportsName {
    color: #171717;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 700;
    line-height: 4.853vw;
    text-align: center;
    margin-top: 3vw;
}

.ExportsRole {
    color: #757575;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5.973vw;
    text-transform: uppercase;
    text-align: center;
}

.ExportsEllipseRight {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    stroke-width: 2px;
    border-radius: 9999px;
    border: 2px #57007B solid;
	cursor: pointer;
    background: #F7F7FA;

    justify-content: center;
    align-items: center;
    display: inline-flex;
	outline: inherit;
}


.ExportsEllipseLeft {
    background: #57007B;
    box-shadow: 0 14px 44px rgba(14.31, 19.82, 34, 0.08);
}

.OurExportsArrow {
    display: none;
}

/*Our Approach*/

.OurApproach {
    height: 55vw;
    margin-top: 3vw;
    border-bottom: 0;
}

.DevelopmentApproach{
    height: 248vw;
    margin-top: 2vw;
}

.TechStack {
    display: flex;
    justify-content: center;
    gap: 3vw;
    margin-top: 5vw;
    box-sizing: border-box;
}


.TechStackNotSelected:hover {
    font-size: 2.8vw;
    font-weight: 600;
    line-height: 4.8vw;
    word-wrap: break-word;
    color: var(--Gray-gray-600, #718096);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: none;
    cursor: pointer;
    transition: 0.3s;
}


.TechStackSelect {
    font-size: 2.8vw;
    font-weight: 600;
    line-height: 4.8vw;
    word-wrap: break-word;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.TechStackNotSelected {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.8vw;

    display: inline-flex;
	border: none;
	cursor: pointer;
    background: none;
    transition: 0.3s;
}

.TechStackImg {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
    margin-top: 3vw;
}

.TechStackImg2 {
    width: 100%;
    height: 100%;
}

.TechStackImgMongo {

}

.TechStackImg image {
    width: 160px;
    height: 80px
}

.TechStackLineGradient {
    width: 20px;
    height: 4px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    margin: auto;
    border-radius: 1rem;
}

.Work {
    height: 215vw;
    margin-top: 1vw;
    /*border-bottom: 1px #E7DAED solid;*/
}

.MobileAlcalineWorks{
    display: block;
    justify-content: center;
    margin-top:6vw;
    margin-left: 4vw;
    gap: 0;
}

.AlcalineWorks {
    display: none;
}

.AlcalineWorksBottom {
    display: none;
}

.AlcalineWorkContainer {
    width: 93.333vw;
    height: 28vw;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid #E7DAED;
    background: #FFF;
    margin-top: 3vw;
}

.AlcalineWorkContainerText {
    color: #1A202C;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.019vw;
}

.AlcalineWorkContainerText2 {
    color: #718096;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5.6vw;
    letter-spacing: -0.019vw;
    word-wrap: break-word;
    margin-left: 1rem;
    margin-top: 1vw;
    width: 86.933vw;
}

.GradiantTextColor {
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.019vw;
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.AlcalineWorkContainerDiv {
    display: flex;
    gap: 1.5vw;
    margin-left: 3vw;
    margin-top: 3vw;
}

.MideLineComponent {
    display: none;
    justify-content: center;
    /*margin-top: 5rem;*/
    /*margin-right: 3rem;*/
}

.ConnectWithUs{
    height: 30.667vw;
    display: flex;
    justify-content: center;
}

.ConnectWithUsDiv1{
    display: flex;
    width: 93.333vw;
    height: 30.667vw;
    flex-shrink: 0;
    border-radius: 8px;
    background: linear-gradient(225deg, #F1F1F5 0%, #E4ECF7 100%);
    gap: 15vw;
}

.ConnectWithUsText {
    /*width: 40vw;*/
    margin: auto;
    text-align: center;
    font-size: 3.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: 5.6vw;
    background: linear-gradient(225deg, #29272E 0%, #27272E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ConnectWithUsButton {
    width: 32vw;
    height: 7vw;
    padding: 6.732px 12.022px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 2.404px;
    justify-content: center;
    align-items: center;
    gap: 0;
    display: inline-flex;
	border: none;
	cursor: pointer;
	outline: inherit;
    margin-top: 7.5vw;
}

.ConnectWithUsButtonText{
    color: #FAFAFA;
    font-size: 2.5vw;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
}

.ConnectWithUsImg{
    width: 47.532px;
    height: 76px;
    flex-shrink: 0;
}
.ConnectWithUsImgDiv {
    margin-right: -22vw;
    margin-top: 0.6vw;
}

.ConnectWithUsDiv2 {
    display: flex;
    justify-content: center;
    margin: auto;
}

.FooterBox {
    height: 75vw;
    display: block;
    margin-top: 5vw;
    /*justify-content: center;*/
    /*margin: auto;*/
    /*gap: 8rem;*/
}

.FooterBoxDiv {
    width: 100%;
    height: 63vw;
    display: block;
    justify-content: center;
    /*margin-left: 1rem;*/
    border-bottom: 1px #E7DAED solid;;

}

.FooterLogo {
    width: 21.333vw;
    height: 14.667vw;
    margin-left: -6vw;
}

.mobileFooterLogo {
    width: 33vw;
    height: 12vw;
    margin-left: 6vw;
}

.FooterBoxText1 {
    width: 94vw;
    color: #718096;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.8vw; /* 166.667% */
    word-wrap: break-word;
    margin-top: 1vw;
}

.FooterLinks {
    display: none;
}

.FooterBoxRight {
    margin-left: 4vw;
}

.FooterLinks2 {
    display: block;
    margin-top: 5vw;
    margin-left: 4vw;
}

.Link {
    color: #4A5568;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.00563rem;
}

.LinksName {
    width: 87vw;
    color: #718096;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.8vw
}

.LinksName a {
    color: #718096;
    list-style: none;
    text-decoration: none;
}

.FooterContent {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 4.5vw;
}

.FooterContentText {
    color: #4A5568;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 0.625rem; /* 71.429% */
    letter-spacing: -0.016vw
}

.ApproachBox {
    display: block;
    justify-content: center;
    margin-top: 3vw;
    gap: 2rem;
    margin-left: 4vw;
}

.Approach {
    width: 93.333vw;
    height: 34.667vw;
    border: 1px solid #E7DAED;
    background: #FAFAFA;
    margin-top: 3vw;
}

.ApproachContent{
    /*display: flex;*/
    justify-content: left;
    margin-top: 4vw;
    margin-left: 3vw;
    gap: 4vw;
}

.ApproachDiv1 {
    width: 11.238vw;
    height: 10.667vw;
    background: var(--Gradient-dark-bg, linear-gradient(225deg, #29272E 0%, #27272E 100%));
    border-radius: 10px;
    padding: 4vw;
}

.ApproachIcon{
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin-top: -1.5vw;
}

.ApproachIconImg{
    width: 23.314px;
    height: 24px;
    flex-shrink: 0;
}

.ApproachDiv2 {
    padding-top: 0.2rem;
    margin-top: 1vw;
}

.ApproachDivText1 {
    color: #1A202C;
    font-size: 3.733vw;
    font-style: normal;
    font-weight: 600;
    line-height: 5.097vw; /* 1.70656rem */
}

.ApproachDivText2 {
    width: 73vw;
    color: #4A5568;
    font-feature-settings: 'salt' on;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.8vw;
    margin-top: 2vw;
}

.SliderButtonDiv {
    display: none;
}

.SliderButton {
    width: 31px;
    height: 13px;
    border-radius: 5px;
    border: 1px solid var(--Base-White, #FFF);
    background: var(--Gradient-Primary-bg, linear-gradient(225deg, #F76680 0%, #57007B 100%));
    cursor: pointer;
}

.SliderButtonRound {
    display: none;
}

.SliderButton {

}

.swiper-slide{
    width: fit-content;
}

.ContactUsdiv{
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 8vw;
    font-size: 2.5em;
    font-weight: 800;
    line-height: 4.4375rem;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%, #57007B 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ContactUsSpan {
    font-size: 6.4vw;
    line-height: 15vw;
}

.ContactUsMain {
    display: block;
    justify-content: center;
    margin: 0;
    width: 82.667vw;
    height: 285vw;
    flex-shrink: 0;
    border-radius: 10px;
    background: none;
    box-shadow: none;
    margin-top: 2em;
    margin-left: 9vw;
}

.LinearGradientBox {
    width: 82.667vw;
    height: 105.867vw;
    background: linear-gradient(180deg, #43040F 0%, #57007B 100%, rgba(129.81, 31.45, 255, 0) 100%);
    border-radius: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.ContactUsdiv2 {
    width: 50vw;
    font-size: 3.733vw;
    margin: 0;
    margin-left: 29vw;
}

.contactInfo {
    color: #FFF;
    font-size: 5.333vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-left: 17vw;
    padding-top: 5vw;
}

.contactInfo2 {
    color: #FFF;
    font-size: 2.933vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2vw;
    margin-left: 18vw;
}

.ContactUsInfo4 {
    display: block;
    margin-top: 3.5vw;
    margin-right: 0;
    margin-left: 38vw;
}

.ContactUsInfo5 {
    margin-top: 10vw;
}

.ContactUsInfo3 {
    width: 343px;
    color: #FFF;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 2vw;
}

.ContactUsInfo6 {
    margin-left: -11vw;
}

.ContactUsInfo7 {
    margin-left: -15vw;
}

.ContactUsInfo8 {
    margin-left: -27vw;
    width: 69vw;
}

.ContactUsInfoImg{
    margin-top: 0;
}

.LinearGradientRound2 {
    width: 12vw;
    height: 12vw;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    border-radius: 9999px;
    margin-top: -70vw;
}

.LinearGradientBox2 {
    margin-left: 57vw;
    margin-top: -14vw;
}

.LinearGradientBox3 {
    width: 31vw;
    height: 31vw;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 9999px;
    margin-left: 7vw;
}

.LinearGradientRound1 {
    width: 14vw;
    height: 14vw;
    background: rgba(255, 248.63, 248.63, 0.13);
    border-radius: 9999px;
    margin-left: 62vw;
    margin-top: 6vw;
}

.ContactInputDiv {
    display: block;
    margin-top: 2rem;
    margin-left: 3rem;
    gap: 3rem;
}

.ContactInputDiv {
    display: block;
    margin-top: 2vw;
    margin-left: 3vw;
}

.ContactUsTextInput {
    border: none;
    border-bottom: 1px solid black;
    width: 83vw;
    margin-top: 1vw;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    background: none;
}

.ContactSubjectDiv {
    margin-top: 10vw;
    margin-left: -0.5vw;
}

.ContactUs {
    margin-bottom: 0;
    overflow-x: hidden;
}

.ContactUsFormButton {
    display: inline-flex;
    padding: 19px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Brand-Primary-bg, #57007B);
    box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
    color: #FAFAFA;
    font-size: 3.453vw;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    word-wrap: break-word;
    margin-top: 10vw;
    margin-left: 17vw;
    width: 50vw;
}

.TickCircle{
    font-size: 3.453vw;
}

.ContactSubjectDivTxt{
    font-size: 3.733vw;
    font-weight: 600;
}

.cursor-follower{
    display: none;
}

.ContactInputDiv{
    display: none;
}

.ContactInputDivMobile{
    display: block;
}

}